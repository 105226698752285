import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Banner",
        "componentName": "Banner"
      }}>{`<Banner show>
  Important announcement regarding the status of your delivery
</Banner>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Banner" mdxType="Props" />
    <h2 {...{
      "id": "show",
      "style": {
        "position": "relative"
      }
    }}>{`Show`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`Visibility of the banner is controlled by the `}<inlineCode parentName="p">{`show`}</inlineCode>{` prop, and clicking the
close button will call the passed in `}<inlineCode parentName="p">{`onClose`}</inlineCode>{` function. This enables you to
programmatically show and hide the banner.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [bannerShown, setBannerShown] = React.useState(true);

  return (
    <>
      <Banner show={bannerShown} onClose={() => setBannerShown(false)} mb={4}>
        Important announcement regarding the status of your delivery
      </Banner>
      <Button size="small" onClick={() => setBannerShown(!bannerShown)}>
        {bannerShown ? 'Close' : 'Open'} banner
      </Button>
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "appearance",
      "style": {
        "position": "relative"
      }
    }}>{`Appearance`}</h2>
    <p><inlineCode parentName="p">{`default: info`}</inlineCode></p>
    <p>{`Banners should be used sparingly to communicate cleary the severity of the issue
or situation. The severity can be indicated by using a different `}<inlineCode parentName="p">{`appearance`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Banner appearance="info" show mb={4}>
  Important announcement regarding the status of your delivery
</Banner>
<Banner appearance="success" show mb={4}>
  Important announcement regarding the status of your delivery
</Banner>
<Banner appearance="error" show>
  Important announcement regarding the status of your delivery
</Banner>
`}</code></pre>
    <h2 {...{
      "id": "closehidden",
      "style": {
        "position": "relative"
      }
    }}>{`CloseHidden`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`If you don't need a close button, you can simply hide it with the `}<inlineCode parentName="p">{`closeHidden`}</inlineCode>{`
prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Banner closeHidden show mb={4}>
  Close button can be hidden with the iconHidden prop.
</Banner>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      